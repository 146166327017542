import {Component, OnInit, ViewChild} from '@angular/core';
import {WebApiHttp} from "../../../../@pristine/process/WebApiHttp.services";
import { NgxSpinnerService } from 'ngx-spinner';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-location-mst',
  templateUrl: './location-mst.component.html',
  styleUrls: ['./location-mst.component.scss']
})
export class LocationMstComponent implements OnInit {

  constructor(private WebApiHttp:WebApiHttp,
              private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.GetLocationMst();
    // this.locationTypeFilter()
  }



  displayedColumn: Array<{
      key: string,
      display_key: string,
      type: string,
      filter: boolean,
      sort: boolean,
      filter_value: string
    }>=[
      {key:'location_id', display_key:'Location Id', type:'text', sort: false, filter_value:'', filter: true},
      {key:'location_name', display_key:'Location Name', type:'date', sort: false, filter_value:'', filter: true},
      {key:'location_type', display_key:'Location Type', type:'text', sort: false, filter_value:'', filter: true},
      {key:'address', display_key:'Address', type:'text', sort: false, filter_value:'', filter: true},
      {key:'city', display_key:'City', type:'text', sort: false, filter_value:'', filter: true},
      {key:'state_name', display_key:'State Name', type:'text', sort: false, filter_value:'', filter: true},
      {key:'country', display_key:'Country', type:'text', sort: false, filter_value:'', filter: true},
      {key:'gst_no', display_key:'Gst No.', type:'text', sort: false, filter_value:'', filter: true},
      {key:'gst_type', display_key:'Gst Type', type:'text', sort: false, filter_value:'', filter: true},
      {key:'contact_no', display_key:'Contact No.', type:'text', sort: false, filter_value:'', filter: true},
      {key:'email', display_key:'Email', type:'text', sort: false, filter_value:'', filter: true},
      {key:'post_code', display_key:'Post Code', type:'text', sort: false, filter_value:'', filter: true},
    ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild('first', {static: true}) paginator: MatPaginator;

  rowsPerPage:number=5
  pageNumber:number=0
  length:any=0

  locationMstArray:Array<LocationModel>=[]

  myPaginatorEvent(event)
  {
    // console.log("page length",event.length,"page size ",event.pageSize,"page index ",event.pageIndex)
    this.rowsPerPage = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.length =      event.length;
    this.GetLocationMst();
  }

  getFilterValeByKey(keyname: string): string {
    let filterlist = this.displayedColumn.filter(item => {
      return item.key == keyname;
    });
    if (filterlist.length > 0)
      return filterlist[0].filter_value;
    return "";
  }

  get getDisplayColumn() {
    return this.displayedColumn.map(a => a.key)
  }

  applyFilter(filtering) {
    if(filtering){
      this.pageNumber =0
    }
    this.GetLocationMst()
  }

  GetLocationMst()
  {
    this.spinner.show()
    let json={
      location_id: this.getFilterValeByKey('location_id'),
      location_name: this.getFilterValeByKey('location_name'),
      location_type: this.getFilterValeByKey('location_type'),
      city: this.getFilterValeByKey('city'),
      state: this.getFilterValeByKey('state'),
      post_code: this.getFilterValeByKey('post_code'),
      address:this.getFilterValeByKey('address'),
      state_name:this.getFilterValeByKey('state_name'),
      gst_no:this.getFilterValeByKey('gst_no'),
      contact_no:this.getFilterValeByKey('contact_no'),
      email:this.getFilterValeByKey('email'),
      rowsPerPage: this.rowsPerPage,
      pageNumber: this.pageNumber,
    }
      this.WebApiHttp.Post(this.WebApiHttp.ApiURLArray.locationmstget,json).then(result=>{
        // console.log(result)
        if(result.length>0 && result[0].condition.toLowerCase()=='true')
        {
          this.locationMstArray = result
          this.dataSource = new MatTableDataSource<LocationModel>(this.locationMstArray)
          this.length = this.locationMstArray[0].total_rows
          // console.log(this.locationMstArray)
        }
        else
        {
          this.locationMstArray = [];
          this.length=0;
          this.dataSource = new MatTableDataSource<LocationModel>([]);
        }
      },error=>{
        console.log(error)
      }).finally(()=>{
        this.spinner.hide()
      })
  }

  locationTypeFilterArray:Array<any>=[]

  locationTypeFilter()
  {
      this.WebApiHttp.Get(this.WebApiHttp.ApiURLArray.worktypeget1).then(result=>{
        // console.log(result)
        if(result.length>0 && result[0].condition.toLowerCase()=='true')
        {
          this.locationTypeFilterArray = result;
        }
        else
        {
          this.locationTypeFilterArray = []
        }
      })

  }



}


interface LocationModel
{
  address: string;
  city: string;
  condition: string;
  contact_no: string;
  country: string;
  created_by: string;
  created_on: string;
  email: string;
  goverment_state_code: string;
  gst_no: string;
  gst_type: string;
  gstin_number: string;
  location_id: string;
  location_name: string;
  location_type: string;
  post_code: string;
  state: string;
  state_name: string;
  total_rows: number;
}




<mat-card class="m-20 p-8" style="max-width: 97%;max-height: 74vh">
    <mat-card-header>
      <b style="font-size: large;margin-top: 10px;">Location List</b>
    </mat-card-header>
    <mat-divider style="margin-top: 8px;"></mat-divider>

    <!-- <div> -->
      <mat-card-content style="max-height: 60vh; overflow: hidden; padding-top:13px ">
        <div class="example-container" style="overflow: auto; max-height: 51vh;margin-top: 7px">
          <table mat-table matSort  [dataSource]="dataSource"  >
            <ng-container [sticky]="column.key=='location_id'" *ngFor="let column of displayedColumn"
                          [matColumnDef]="column.key">
              <th mat-header-cell *matHeaderCellDef>
                <div class="filterHeader">
                  <b mat-sort-header >{{column.display_key}}</b>
                  <br>
                  <input [style.visibility]=" column.key=='country'?'hidden':'block'  &&
                  column.key=='gst_type'?'hidden':'block' "
                    type="text" (keyup)="applyFilter(true)"
                          [(ngModel)]="column.filter_value"
                         [placeholder]="column.display_key" style="margin-left: -4px;"
                  *ngIf="column.key!='location_type' && column.key!='address' ;else elseblock">

                  <ng-template #elseblock>

<!--                    <input type="text" [placeholder]="column.display_key" [matAutocomplete]="auto"  (click)="locationTypeFilter()"-->
<!--                           (keyup.enter)="applyFilter(true)" [(ngModel)]="column.filter_value"-->
<!--                           (keydown.backspace)="column.filter_value='';applyFilter(true)">-->
<!--                    <mat-autocomplete  #auto="matAutocomplete">-->
<!--                      <mat-option  *ngFor="let option of locationTypeFilterArray" [value]="option.work_type_name">-->
<!--                        {{option.work_type_name}}-->
<!--                      </mat-option>-->
<!--                    </mat-autocomplete>-->
                    <input *ngIf="column.key=='address'"
                           type="text" (keyup)="applyFilter(true)"
                           [(ngModel)]="column.filter_value"
                           [placeholder]="column.display_key" style="margin-left: -4px;" >


                    <mat-select [style.visibility]="column.key=='address'?'hidden':'block'" (click)="locationTypeFilter()"
                                style="width: 140px;height: 35px;border-radius: 10px;margin-bottom: 2px;
                                background-color: #f4f4f4;padding: 5px"
                                (keydown.backspace)="column.filter_value='';applyFilter(true)"
                                (selectionChange)="applyFilter(true)" [(ngModel)]="column.filter_value">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let rol of locationTypeFilterArray" [value]="rol.work_type_name" selected="All">
                        {{rol.work_type_name}}
                      </mat-option>
                    </mat-select>

                  </ng-template>
                </div>
              </th>
              <td mat-cell *matCellDef="let element;">
                <p *ngIf="column.key!='address'">{{element[column.key]}}</p>
                <span *ngIf="column.key=='address'" class="text" style="width: 280px" matTooltipClass="tooltip"  matTooltip="{{element[column.key]}}">{{element[column.key]}}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="getDisplayColumn;sticky:true "></tr>
            <tr mat-row *matRowDef="let row; columns: getDisplayColumn;"></tr>
          </table>
          <b style="padding: 5px" *ngIf="dataSource?.data?.length==0">No Record Found.</b>
        </div>
      </mat-card-content>

     <mat-card-actions>
      <mat-paginator #first [pageSizeOptions]="[5,10,20,50,100]" showFirstLastButtons
      [pageSize]="rowsPerPage"
      [pageIndex]="pageNumber"
      [length]="length"
      (page)="myPaginatorEvent($event)"></mat-paginator>
     </mat-card-actions>
</mat-card>

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {environment} from '../environments/environment';
import {RouterModule, Routes} from "@angular/router";
import {pristineSharedModule} from "../@pristine/shared.module";
import {pristineProgressBarModule, pristineSidebarModule, pristineThemeOptionsModule} from "../@pristine/components";
import {HttpClientModule} from "@angular/common/http";
import {pristineModule} from "../@pristine/pristine.module";
import {pristineConfig} from "./pristine-config";
import {LayoutModule} from "./layout/layout.module";
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ToastrModule } from 'ngx-toastr';
import { NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import { CreateshipmentComponent } from './main/shipment/createshipment/createshipment.component';
import { values } from 'lodash';
import { MatTableModule } from '@angular/material/table';
import {MatDividerModule} from '@angular/material/divider';
import {MatPaginatorModule} from "@angular/material/paginator";
import {BrowserModule} from "@angular/platform-browser";
import {MatSortModule} from "@angular/material/sort";
import { LocationMstComponent } from './main/masters/location-mst/location-mst.component';
import {MatCardModule} from "@angular/material/card";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import { ExceptionReportComponent } from './main/reports/exception-report/exception-report.component';
import {MatBadgeModule} from "@angular/material/badge";
import {MatMenuModule} from "@angular/material/menu";
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
// import { LiquidationReportComponent } from './main/Reports/liquidation-report/liquidation-report.component';

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'setup',
    loadChildren: () => import('./main/Setup/Setup.module').then(value => value.SetupModule)
  },
  {
    path:'shipment',
    loadChildren: () => import('./main/shipment/shipmentmodule.module').then(value => value.ShipmentmoduleModule)
  },
  {
    path:'master',
    loadChildren: () => import('./main/masters/masters.module').then(value => value.MastersModule)
  },
  {
    path:'reports',
    loadChildren: () => import('./main/reports/reports.module').then(value => value.ReportsModule)
  },

  {
    path: 'mydashboard',
    redirectTo: ''
  },
  {
    path: '**',
    redirectTo: '/pages/auth/login-2'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    CreateshipmentComponent,
    LocationMstComponent,
    ExceptionReportComponent,
    // LiquidationReportComponent
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      progressAnimation: 'decreasing',
      closeButton: true,

    }), // ToastrModule added
    NgxSpinnerModule,
    RouterModule.forRoot(appRoutes, {useHash: true}),
    // Material moment date module
    MatMomentDateModule,
    MatTableModule,
    MatDividerModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    // pristine modules
    pristineModule.forRoot(pristineConfig),
    pristineProgressBarModule,
    pristineSharedModule,
    pristineSidebarModule,
    pristineThemeOptionsModule,


    // App modules
    LayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatPaginatorModule,
    MatTableModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatBadgeModule,
    MatMenuModule,
    ClipboardModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatDatepickerModule,
    // MatPaginatorModule
  ],
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'en-IN'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


<div class="page-layout simple fullwidth" style="max-width: 97%;max-height: 85vh" fxLayout="column">
  <div class="mat-card mat-elevation-z1 m-12 p-2"  style="max-width: 85%;
      padding: 0 !important;max-height: 85vh" fxLayout="column" fxLayoutAlign="start">

    <div fxLayout="row" fxLayoutGap="auto" style="margin-top: 15px;margin-left: 25px; margin-bottom: 5px !important;">
      <div>
        <b style="font-size: large;margin-top: 15px;">Create Shipment </b>
      </div>
         <div style="width: 80%">
           <button mat-icon-button [matTooltipPosition]="'below'"    matTooltip="Download Excel"  (click)="sample_packing()"
                   style="margin-right: 15px;float: right;box-shadow: 1px 1px 1px 1px rgba(170,170,170,0.42)">
             <mat-icon>cloud_download</mat-icon>
           </button>
         </div>
    </div>

        <div class="p-16" fxFlex fxLayout="column" fxLayout.lt-sm="column" style="max-height: 60vh" >
          <div>
            <div class="p-8" fxLayout="column" fxFlex="100" fxLayoutGap="2%" fxLayoutGap="30px">
              <div class="image-upload-wrap" fxFlex="100" (click)="fileOpener()" *ngIf="hideDragDrop">
                <div class="drag-text">
                  <h3>Drag and drop a file or Just click</h3>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="40px">
                <div>
                  <button mat-raised-button color="accent" style="width: 170px;" (click)="excelUpload()" [disabled]="Enablebutton">Upload Excel</button>
                </div>
                <div>
                  <button mat-raised-button color="accent" style="width: 170px;" (click)="deleteExcel()"  [disabled]="hideDelete">Delete Excel</button>
                </div>
              </div>
            </div>
          </div><br>
          <div  [style.display]="hideExcelTable?'block':'none'">
              <div>
                <h1>Excel Data</h1>
              </div>
              <div class="example-container" style="max-height: 41vh">
                <table mat-table matSort [dataSource]="dataSource">
                  <ng-container  *ngFor="let column of displayColumns"
                                 [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef>
                      <div class="filterHeader">
                        <b mat-sort-header >{{column|firstAnd_Split}}</b>
<!--                        <b mat-sort-header *ngIf="column=='dispatch_from_id'">Dispatch From Id</b>-->
                        <br>
                        <input #entry (keyup)="applyFilter(column,entry.value)"
                               [placeholder]="column|firstAnd_Split">
                      </div>
                    </th>
                    <td mat-cell *matCellDef="let element; let i = index">
                      <p *ngIf="column!='dc_or_invoice_date' && column!='dispatch_to'">{{element[column]}}</p>
                      <p *ngIf="column=='dc_or_invoice_date'" style="width: 160px">{{element[column] }}</p>
                      <p *ngIf="column=='dispatch_to'" style="width: 260px">{{element[column]}}</p>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayColumns;sticky:true"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
                </table>
                <b style="padding: 5px" *ngIf="dataSource?.data?.length==0">No Record Found.</b>
              </div>
              <mat-paginator #first [pageSizeOptions]="[10,20,50,100]" showFirstLastButtons></mat-paginator>
          </div>
        </div>

      </div>

</div>

<!--<div class="page-layout simple fullwidth inner-scroll" fxLayout="column" id="UserSetup">-->
<!--  <div class="p-12" pristinePerfectScrollbar>-->
<!--    <div class="mb-24" fxLayout="column" fxLayoutAlign="start">-->
<!--      <div class="mat-card mat-elevation-z1" fxFlex="1 0 auto" fxLayout="column"-->
<!--           fxLayoutAlign="start">-->
<!--        <div class="h3 p-8">-->
<!--          <b style="font-size: large;margin-top: 15px;">Create Shipment </b>-->
<!--          <button mat-icon-button [matTooltipPosition]="'below'"    matTooltip="Download Excel"  (click)="sample_packing()"-->
<!--                  style="margin-right: 15px;float: right;box-shadow: 1px 1px 1px 1px rgba(170,170,170,0.42)">-->
<!--            <mat-icon>cloud_download</mat-icon>-->
<!--        </button>-->
<!--        </div>-->

<!--        <div class="p-16" fxFlex fxLayout="column" fxLayout.lt-sm="column" style="max-height: 60vh" >-->

<!--          <div>-->
<!--            <div class="p-8" fxLayout="column" fxFlex="100" fxLayoutGap="2%" fxLayoutGap="30px">-->
<!--              <div class="image-upload-wrap" fxFlex="100" (click)="fileOpener()" *ngIf="hideDragDrop">-->
<!--                  <div class="drag-text">-->
<!--                      <h3>Drag and drop a file or Just click</h3>-->
<!--                  </div>-->
<!--              </div>-->
<!--              <div fxLayout="row" fxLayoutGap="40px">-->
<!--                  <div>-->
<!--                    <button mat-raised-button color="accent" style="width: 170px;" (click)="excelUpload()" [disabled]="Enablebutton">Upload Excel</button>-->
<!--                  </div>-->
<!--                  <div>-->
<!--                  <button mat-raised-button color="accent" style="width: 170px;" (click)="deleteExcel()"  [disabled]="hideDelete">Delete Excel</button>-->
<!--                  </div>-->
<!--              </div>-->
<!--          </div>-->
<!--          </div><br><br>-->
<!--          <div *ngIf="hideExcelTable">-->
<!--          <div>-->
<!--            <h1>Excel Data</h1>-->
<!--          </div>-->
<!--                <div class="example-container" style="max-height: 45vh">-->
<!--                    <table mat-table matSort [dataSource]="dataSource">-->
<!--                        <ng-container  *ngFor="let column of displayColumns"-->
<!--                                      [matColumnDef]="column">-->
<!--                            <th mat-header-cell *matHeaderCellDef>-->
<!--                                <div class="filterHeader">-->
<!--                                    <b mat-sort-header>{{column|firstAnd_Split}}</b>-->
<!--                                    <br>-->
<!--                                    <input #entry (keyup)="applyFilter(column,entry.value)"-->
<!--                                           [placeholder]="column|firstAnd_Split">-->
<!--                                </div>-->
<!--                            </th>-->
<!--                            <td mat-cell *matCellDef="let element; let i = index">-->
<!--                                <p *ngIf="column!='dc_or_invoice_date'">{{element[column]}}</p>-->
<!--                                <p *ngIf="column=='dc_or_invoice_date'" style="width: 160px">{{element[column]}}</p>-->
<!--                            </td>-->
<!--                        </ng-container>-->

<!--                        <tr mat-header-row *matHeaderRowDef="displayColumns;sticky:true"></tr>-->
<!--                        <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>-->
<!--                    </table>-->
<!--                  <b style="padding: 5px" *ngIf="dataSource?.data?.length==0">No Record Found.</b>-->
<!--                </div>-->
<!--&lt;!&ndash;                   <mat-paginator  [pageSizeOptions]="[10,20,50,100]" showFirstLastButtons></mat-paginator>&ndash;&gt;-->
<!--            <mat-paginator [length]="100"-->
<!--                           [pageSize]="10"-->
<!--                           [pageSizeOptions]="[5, 10, 25, 100]"-->
<!--                           aria-label="Select page"-->
<!--                           showFirstLastButtons>-->
<!--            </mat-paginator>-->
<!--          </div>-->

<!--        </div>-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



















<!-- <div class="postedexample-container12">
  <table  mat-table matSort>
    <ng-container
                  *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <th *matHeaderCellDef mat-header-cell>
        <div class="filterHeader">
          <b mat-sort-header>{{column|firstAnd_Split}}</b><br>
          <br>
          <input (keyup)="applyFilter($event.target.value,column)"
                 *ngIf="column!='Action' && column!='Password'"
                 [placeholder]="column|firstAnd_Split"
                 matInput>
        </div>
      </th>
      <td *matCellDef="let element" mat-cell>
        <p *ngIf="column!='Action' &&column!='Password' && column!='is_cluster' &&column!='is_mark_nf' ">{{element[column]}}</p>

        <button matTooltip="Update Password" (click)="EditUser(element)" *ngIf="column=='Password'"
                mat-icon-button class="header_button">
          <mat-icon>lock</mat-icon>
        </button>
        <button (click)="NewEditUser(element)" matTooltip="Update User" *ngIf="column=='Action'"
                mat-icon-button class="header_button">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
  </table>
  <div *ngIf="dataSource?.data?.length<=0" class="h3 m-16">No Record Found.</div>
  <mat-divider *ngIf="dataSource?.data?.length<=0"></mat-divider>
</div> -->

import { SessionManageMent } from './../../../../@pristine/process/SessionManageMent';
import { WebApiHttp } from './../../../../@pristine/process/WebApiHttp.services';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTable} from '@angular/material/table'
import {MatTableDataSource} from "@angular/material/table";
import { ExcelService } from 'src/@pristine/process/excel.Service';
import * as XLSX from 'xlsx';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { isInteger, isNumber, isRegExp, isSymbol, result } from 'lodash';
import {getLocaleDateFormat, DatePipe, formatDate} from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-createshipment',
  templateUrl: './createshipment.component.html',
  styleUrls: ['./createshipment.component.scss']
})
export class CreateshipmentComponent implements OnInit {

  constructor(
    private _excelService: ExcelService,
    private _toasterService:ToastrService,
    private spinner: NgxSpinnerService,
    private WebApiHttp: WebApiHttp,
    private _session: SessionManageMent,
    private datepipe: DatePipe
  ) { }

    displayColumns: string[] = ['tag_sl_no','transfer_type','dc_or_invoice_date','dc_or_invoice_no','dispatch_from_id','zone','dispatch_to_id','dispatch_to',
    'place','branch_or_party','crop','hybrid_name','lot_no','packing_size','qty_in_pkt','qty_in_kg','no_of_bags','expiry_date','transporter','lr_no',
    'lr_date','vehicle_no']

    dataSource: MatTableDataSource<ExcelModel> = new MatTableDataSource<ExcelModel>([])
    // @ViewChild('first', {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @ViewChild('first',{static:true}) paginator: MatPaginator


  ngOnInit(): void {
  }
  sample_packing() {
    var temppoline: Array<any> = [];
    for (var i = 0; i < 1; i++) {
      var json = {
        tag_sl_no: '',
        transfer_type:'',
        dc_or_invoice_date:'',
        dc_or_invoice_no:'',
        dispatch_from_id:'',
        zone:'',
        dispatch_to_id:'',
        dispatch_to:'',
        place:'',
        branch_or_party:'',
        crop:'',
        hybrid_name:'',
        lot_no:'',
        packing_size:'',
        qty_in_pkt:'',
        qty_in_kg:'',
        no_of_bags:'',
        expiry_date:'',
        transporter:"",
        lr_no:'',
        lr_date:'',
        vehicle_no:''
      }
      temppoline.push(json);
    }
    this._excelService.exportAsExcelFile(temppoline, 'SampleShipment');
  }

  fileOpener()
  {
    var input_element: any = document.createElement('input');
    input_element.setAttribute('type', 'file');
    input_element.setAttribute('accept', '.csv,.xlsx,.xls,');
    input_element.click();
    input_element.addEventListener('change', event => {
      this.incomingfile(event)
    });
  }

  arrayBuffer: any;
  file: File;
  validateUploadorNot: boolean = false;
  progressValue: number = 0;
  uploadedData: ExcelModel[];
  progressbarshow: boolean = false;
  showData:Array<ExcelModel>=[];
  Enablebutton:boolean=true;
  expiry_date = new Date()
  hideDragDrop:boolean = true;
  hideExcelTable:boolean = false
  hideDelete:boolean = true


  regexp =/[^a-zA-Z0-9 ]/

   async incomingfile(event)
  {
    this.progressValue = 20;
    this.progressbarshow = true;
    this.file = event.target.files[0];
    if (this.file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.file.type == 'application/vnd.ms-excel' || this.file.type=='text/csv')
    {
      var subscriberOfobservable = new Observable(observable =>
      {
        try
        {
          let fileReader = new FileReader();
          fileReader.onload = (e) =>
          {
            this.progressValue = 50;
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            this.progressValue = 75;
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            this.progressValue = 80;
            var workbook = XLSX.read(bstr, {type: "binary", raw: true, cellText: true, cellStyles: true});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            this.uploadedData = XLSX.utils.sheet_to_json(worksheet, {
              defval:"",
              raw: false,
              dateNF: "DD-MM-YYYY;@"
            });
            this.showData = this.uploadedData
            //condition + loop
            console.log("Excel data -->",this.showData);
            if(this.showData.length<=0)
              {
                this._toasterService.error('Error', 'Excel is Empty');
                this.Enablebutton=true
                return
              }


            for(let i=0; i<this.showData.length; i++)
            {
              let d:string = this.showData[i].expiry_date
              let dc_date:string = this.showData[i].dc_or_invoice_date
              let lr_date:string = this.showData[i].lr_date



              let r = d?.indexOf('-')>-1?d.split('-')[2]+'/'+d.split('-')[1]+'/'+d.split('-')[0]:''
              let dc = dc_date?.indexOf('-')>-1?dc_date.split('-')[2]+'/'+dc_date.split('-')[1]+'/'+dc_date.split('-')[0]:''
              let lr = lr_date?.indexOf('-')>-1?lr_date.split('-')[2]+'/'+lr_date.split('-')[1]+'/'+lr_date.split('-')[0]:''


              try {
                if(this.showData[i].expiry_date != this.datepipe.transform(r,'dd-MM-yyyy'))
                {
                  this._toasterService?.error('error',`Invalid Date Format in expiry date ${r} at line ${i} , provide yyyy-mm-dd` );
                  return;
                }
                  this.showData[i].expiry_date = this.datepipe.transform(r, 'dd-MM-yyyy') ? this.datepipe.transform(r, 'yyyy-MM-dd') : ''
              }
              catch (e) {
                this._toasterService?.error('Error', `Invalid date format in expiry date ${r} at line ${i}, provide yyyy-mm-dd`);
                this.Enablebutton=true
                return;
              }
              try{
                // console.log(this.showData[i].dc_or_invoice_date,this.datepipe.transform(dc,'dd-MM-yyyy'),this.showData[i].dc_or_invoice_date != this.datepipe.transform(dc,'dd-MM-yyyy'))
                if(this.showData[i].dc_or_invoice_date != this.datepipe.transform(dc,'dd-MM-yyyy'))
                {
                  this._toasterService?.error('error',`Invalid Date Format dc_or_invoice_date ${dc} at line ${i} , provide yyyy-mm-dd` );
                  return;
                }
               this.showData[i].dc_or_invoice_date = this.datepipe.transform(dc, 'dd-MM-yyyy') ? this.datepipe.transform(dc, 'yyyy-MM-dd') : ''

              }
              catch(e)
                {
                  this._toasterService?.error('Error', `Invalid date format dc_or_invoice_date ${dc} at line ${i}, provide yyyy-mm-dd`);
                  this.Enablebutton=true
                  return;
              }
              try{
                if(this.showData[i].lr_date != this.datepipe.transform(lr,'dd-MM-yyyy'))
                {
                  this._toasterService?.error('error',`Invalid Date Format lr_date ${lr} at line ${i} , provide yyyy-mm-dd` );
                  return;
                }
                this.showData[i].lr_date = this.datepipe.transform(lr, 'dd-MM-yyyy') ? this.datepipe.transform(lr, 'yyyy-MM-dd') : ''

              }
              catch(e)
                {
                  this._toasterService?.error('Error', `Invalid date format lr_date ${lr} at line ${i}, provide yyyy-mm-dd`)
                  this.Enablebutton=true
                  return;
              }



              if(this.showData[i].tag_sl_no=='' || this.showData[i].tag_sl_no==null || this.showData[i].tag_sl_no==undefined)
              {
                this._toasterService.error('Error',"tag_sl_no is required");
                this.Enablebutton=true
                return
              }

              if(this.showData[i].tag_sl_no.charAt(0) !='`')
              {
                this._toasterService.error('Error' , 'tag_sl_no is invalid')
                this.Enablebutton = true
                return
              }
              if((this.showData[i].tag_sl_no.match(/`/g) || []).length>1)
              {
                this._toasterService.error('Error' , 'Backtick is greater than  1')
                this.Enablebutton = true
                return
              }

              if(this.showData[i].transfer_type=='' || this.showData[i].transfer_type==null || this.showData[i].transfer_type==undefined)
              {
                this._toasterService.error('Error',"Transfer Type is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].dc_or_invoice_date=='' ||this.showData[i].dc_or_invoice_date==null || this.showData[i].dc_or_invoice_date==undefined)
              {
                this._toasterService.error('Error',"dc_or_invoice_date is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].dc_or_invoice_no=='' || this.showData[i].dc_or_invoice_no==null || this.showData[i].dc_or_invoice_no==undefined)
              {
                this._toasterService.error('Error',"dc_or_invoice_no is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].dispatch_from_id=='' || this.showData[i].dispatch_from_id==null || this.showData[i].dispatch_from_id==undefined)
              {
                this._toasterService.error('Error',"dispatch_from is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].zone=='' || this.showData[i].zone==null || this.showData[i].zone==undefined)
              {
                this._toasterService.error('Error',"zone is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].dispatch_to_id=='' || this.showData[i].dispatch_to_id==null || this.showData[i].dispatch_to_id==undefined)
              {
                this._toasterService.error('Error',"dispatch_to_id is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].dispatch_to=='' || this.showData[i].dispatch_to==null || this.showData[i].dispatch_to==undefined)
              {
                this._toasterService.error('Error',"dispatch_to is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].place=='' || this.showData[i].place==null || this.showData[i].place==undefined)
              {
                this._toasterService.error('Error',"place is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].branch_or_party=='' || this.showData[i].branch_or_party==null || this.showData[i].branch_or_party==undefined)
              {
                this._toasterService.error('Error',"branch_or_party is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].crop=='' || this.showData[i].crop==null || this.showData[i].crop==undefined)
              {
                this._toasterService.error('Error',"crop is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].hybrid_name=='' || this.showData[i].hybrid_name==null || this.showData[i].hybrid_name==undefined)
              {
                this._toasterService.error('Error',"hybrid_name is required");
                this.Enablebutton=true
                return
              }


              if(this.showData[i].lot_no=='' || this.showData[i].lot_no==null || this.showData[i].lot_no==undefined)
              {
                this._toasterService.error('Error',"lot_no is required");
                this.Enablebutton=true
                return
              }


                if(this.showData[i].lot_no.match(this.regexp))
                {
                  this._toasterService.error('Error',"lot_no is Invalid");
                  this.Enablebutton=true;
                  return
                }

              if(!parseFloat(this.showData[i].packing_size))
              {
                this._toasterService.error("Error","Invalid packing size")
                this.Enablebutton = true
                return
              }
              if(this.showData[i].packing_size=='' || this.showData[i].packing_size==null || this.showData[i].packing_size==undefined)
              {
                this._toasterService.error('Error',"packing_size is required");
                this.Enablebutton=true
                return
              }
              // if(!parseFloat(this.showData[i].qty_in_pkt))
              // {
              //   console.log(!isInteger(this.showData[i].qty_in_pkt));
              //
              //   this._toasterService.error("Error","Invalid qty_in_pkt")
              //   this.Enablebutton = true
              //   return
              // }
              if(this.showData[i].qty_in_pkt=='' || this.showData[i].qty_in_pkt==null || this.showData[i].qty_in_pkt==undefined)
              {
                this._toasterService.error('Error',"qty_in_pkt is required");
                this.Enablebutton=true
                return
              }
              if(parseInt(this.showData[i].qty_in_pkt) != Number(this.showData[i].qty_in_pkt))
              {
                this._toasterService.error('Error',"Qty in Pkt is invalid")
                this.Enablebutton = true
                return;
              }
              if(!parseFloat(this.showData[i].qty_in_kg))
              {
                this._toasterService.error("Error","Invalid qty_in_kg")
                this.Enablebutton = true
                return
              }
              if(this.showData[i].qty_in_kg=='' || this.showData[i].qty_in_kg==null || this.showData[i].qty_in_kg==undefined)
              {
                this._toasterService.error('Error',"qty_in_kg is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].no_of_bags=='' || this.showData[i].no_of_bags==null || this.showData[i].no_of_bags==undefined)
              {
                this._toasterService.error('Error',"no_of_bags is required");
                this.Enablebutton=true
                return
              }
              if(parseInt(this.showData[i].no_of_bags) != Number(this.showData[i].no_of_bags))
              {
                this._toasterService.error("Error","Invalid no of bags")
                this.Enablebutton = true
                return
              }


              if(this.showData[i].expiry_date == '' || this.showData[i].expiry_date==null || this.showData[i].expiry_date==undefined)
              {
                this._toasterService.error('Error',"expiry_date is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].transporter=='' || this.showData[i].transporter==null || this.showData[i].transporter==undefined)
              {
                this._toasterService.error('Error',"transporter is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].lr_no=='' || this.showData[i].lr_no==null || this.showData[i].lr_no==undefined)
              {
                this._toasterService.error('Error',"lr_no is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].lr_date=='' || this.showData[i].lr_date==null || this.showData[i].lr_date==undefined)
              {
                this._toasterService.error('Error',"lr_date is required");
                this.Enablebutton=true
                return
              }
              if(this.showData[i].vehicle_no=='' || this.showData[i].vehicle_no==null || this.showData[i].vehicle_no==undefined)
              {
                this._toasterService.error('Error',"vehicle_no is required");
                this.Enablebutton=true
                return
              }
              // this.showData[i].no_of_bags = parseFloat(Math.round(this.showData[i].no_of_bags).toFixed(0))

            }
            if(this.showData.length>0)
            {
              this.dataSource = new MatTableDataSource<ExcelModel>(this.showData);
              console.log("show data",this.showData)
              this.dataSource.paginator = this.paginator;
              this.Enablebutton=false;
              this.hideExcelTable =  true
              this.hideDragDrop = false;
              this.hideDelete = false
            }
          }
            fileReader.readAsArrayBuffer(this.file);
        }
        catch (error) {
          console.log(error);
          this._toasterService.error('Error', 'File is Not Readable')
          this.progressbarshow = false;
        }
      });
      this.validateUploadorNot = true;
      subscriberOfobservable.subscribe(result => {
        // console.log("result--->", result);
      });
    }

  }

  applyFilter(key,val)
  {
    let filterdata = this.showData.filter((ele)=>{
      return ele[key].toLowerCase().includes(val.toLowerCase())
    })
    this.dataSource = new MatTableDataSource<ExcelModel>(filterdata)
    this.dataSource.paginator = this.paginator
  }


  excelUpload() {
    if(this.showData.length>0)
    {
      for(let i=0;i<this.showData.length;i++)
      {
          console.log(typeof (this.showData[i].tag_sl_no))
          this.showData[i].tag_sl_no = this.showData[i].tag_sl_no.replace('`',"")
          this.showData[i].dispatch_from = this.showData[i].dispatch_from_id
      }
      let json =
        {
          created_by:this._session.getEmail,
          lines:this.showData
        }
            this.WebApiHttp.Post(this.WebApiHttp.ApiURLArray.shipmentUploadExcel,json).then(result=>
          {
            if(result.length>=0 && result[0].condition.toLowerCase() == 'true')
            {
              this._toasterService.success('success', result[0].message);
              this.hideDragDrop = true;
              this.hideExcelTable = false;
              this.hideDelete = true;
            }else
            {
              this._toasterService.error('error', result[0].message);
              this.dataSource = new MatTableDataSource(null);
              this.hideDragDrop = true;
              this.Enablebutton = true;
              this.hideExcelTable = false;
              this.hideDelete = true;
            }
          })
    }
    else
      {
        this._toasterService.error('error','invalid excel')
      }

    this.Enablebutton=true;
    this.hideDelete = true;
    this.hideDragDrop = true;
    this.hideExcelTable = false
    this.showData=[];

  }

  deleteExcel()
  {
    this.dataSource = new MatTableDataSource(null);
    this.hideDragDrop = true;
    this.Enablebutton = true;
    this.hideExcelTable = false;
    this.hideDelete =true;
  }


}


interface ExcelModel
{
  tag_sl_no:string;
  transfer_type:string;
  dc_or_invoice_date:string;
  dc_or_invoice_no:string;
  dispatch_from_id:string;
  dispatch_from:string;
  zone:string;
  dispatch_to_id:string;
  dispatch_to:string;
  place:string;
  branch_or_party:string;
  crop:string;
  hybrid_name:any;
  lot_no:string;
  packing_size:string;
  qty_in_pkt:string;
  qty_in_kg:string;
  no_of_bags:string;
  expiry_date:string;
  transporter:string;
  lr_no:string;
  lr_date:string;
  vehicle_no:string;
}


import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {EncriptDecript} from "./process/EncriptDecript";
import {SignalR} from "./process/SignalR";
import {AuthGuard} from "./process/AuthGuard";
import {ExcelService} from "./process/excel.Service";
import {WebApiHttp} from "./process/WebApiHttp.services";
import {ValidateResponse} from "./process/ValidateResponse";
import {SessionManageMent} from "./process/SessionManageMent";
import {pristine_CONFIG} from "./services/config.service";
import {ReportDownload} from "./process/report-download";
import {DatePipe} from "@angular/common";
import {MaxRangeSelectionStrategy} from "./process/DateRange.services";
import {DateRangeService} from "./process/date-range.service";

@NgModule()
export class pristineModule {
  constructor(@Optional() @SkipSelf() parentModule: pristineModule) {
    if (parentModule) {
      throw new Error('pristineModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders<any> {
    return {
      ngModule: pristineModule,
      providers: [DateRangeService,EncriptDecript,DatePipe, ValidateResponse,MaxRangeSelectionStrategy, WebApiHttp, ExcelService, AuthGuard, SignalR, ReportDownload, SessionManageMent,
        {
          provide: pristine_CONFIG,
          useValue: config
        }
      ]
    };
  }
}


import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ExcelService} from "./excel.Service";
import {ToastrService} from "ngx-toastr";
import {WebApiHttp} from "./WebApiHttp.services";
// import {IReportStructure} from "../process/excel.Service"
import  {ReportsStructure} from "../../app/main/reports/exception-report/FilterArray";
import {DatePipe} from "@angular/common";
import {SessionManageMent} from "./SessionManageMent";
import {log} from "util";
// import {IReportStructure}
@Injectable({
  providedIn: 'root'
})
export class ReportDownload {

  constructor(private excel : ExcelService,
              private webApiHttp: WebApiHttp,
              private datepipe: DatePipe,
              private _session: SessionManageMent,
              private _toaster: ToastrService) {
    // this.storageArray = localStorage.getItem('storage')?JSON.parse(localStorage.getItem('storage')):[]
    // this.downloading= localStorage.getItem('downloader')?JSON.parse(localStorage.getItem('downloader')):[];
    // console.log(this.storageArray, this.downloading)
    // this.downloadingArray.next(this.downloading);


    setInterval(()=>{
      let x =0
      this.storageArray.forEach(ele=>{
        x= (ele?.downloaded && ele?.downloadStarted?1: 0) + x
      })
      this.count = x
      this.readyToSaveCount.next(this.count)
    },500)
    this.Queing()
  }

  downloading: Array<downloadArray> = [];
  storageArray: Array<storageArray> = [];
  downloadingArray:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  count = 0;
  readyToSaveCount:BehaviorSubject<number> = new BehaviorSubject(0);
  inProgress : boolean = true;
  rowCount = 100000
  pageNo = 0;
  totalPages = 0;
  fetchedData:any=null

  getData(fetchData: any){
    this.fetchedData = fetchData
    // console.log("fetchdata",this.fetchedData);
    let invalid = false;
    let cols = []
    let allCols = [];
    let locationKey:string='';
    let json={};
    let minSelected: boolean = false;

    //get selected columns
    fetchData?.filter_columns.map((ele,i) => {
      // console.log(ele?.selected , (ele?.filter_value == '' && ele.type !='daterange') ,ele, (ele?.start==null ||ele?.end==null ))
      if(ele?.required && !ele?.selected ){
        this._toaster.error(ele?.name + ' is required.')
        invalid = true;
        return
      }else if(ele?.selected && ((ele?.filter_value == '' && ele.type !='daterange') || ((ele?.start==null ||ele?.end==null )&& ele.type =='daterange'))){
        this._toaster.error(
           ele?.name + ' can not be blank.')
        invalid = true;
        return
      } else {
        if(ele.selected || fetchData?.name =='liquidationExceptionreport' || fetchData?.name == 'stockReport' || fetchData?.name == 'dispatchReport' ||
        fetchData?.name =='summaryReport' || fetchData?.name =='liquidationReport' ){
          minSelected = true;
        }
        if (ele?.type == 'daterange')
        {
          json[ele?.start_key] = ele?.selected ?
            (this.datepipe.transform(ele?.start, 'yyyy-MM-dd') ? this.datepipe.transform(ele?.start, 'yyyy-MM-dd') : '') : '';
          json[ele?.end_key] = ele?.selected ? (this.datepipe.transform(ele?.end, 'yyyy-MM-dd') ? this.datepipe.transform(ele?.end, 'yyyy-MM-dd') : json[ele?.start_key]) : '';
          // console.log(ele?.end,this.datepipe.transform(ele?.end, 'yyyy-MM-dd'))
        }
        else if (ele?.type == 'date') {
          json[ele?.key] = ele?.selected ? this.datepipe.transform(ele?.filter_value, 'yyyy-MM-dd') : '';
        }else if (ele?.type == 'number') {
          json[ele?.key] = ele?.selected ? Number(ele?.filter_value) : -1;
        } else if (ele?.type == 'range') {
          json[ele?.key] = ele?.selected ? Number(ele?.filter_value) : 0;
        } else if (ele?.type == 'text') {
          json[ele?.key] = ele?.selected ? (ele?.filter_value) : '';
        }else if (ele?.type == 'email') {
          json[ele?.key] = this._session.getEmail;
        } else {
          json[ele?.key] = ele?.selected ? ele?.filter_value : (ele?.type=='boolean_1'?'-1':'');
        }
      }
    })
    if(fetchData?.isMulti){
      json['pageNumber']=this.pageNo;
      json['rowsPerPage']=this.rowCount;
    }
    if (invalid) return;
    if(!minSelected){
      this._toaster.error('Select atleast one filter.')
      return
    }
    //create filter json
    // fetchData?.columns.map((ele)=>{
    //   (ele?.selected)?cols.push(ele.key):''
    // })

    //throw error on black column array
    // if(cols?.length<1){
    //   this._toaster.error('Select Columns to generate report')
    //   return
    // }




    //Store values in list
    let id_val = (new Date()).getTime().toString()
    this.storageArray.push({
      downloadProgress: true,
      id: id_val,
      name: fetchData.displayName,
      data:'',
      downloadStarted: false,
      downloaded: false,
      fileDownload:false,
      message:'',
      url: fetchData['url'],
      cols: cols,
      allCols: allCols,
      isMulti: fetchData?.isMulti,
      csv: fetchData?.csv,
      jsonList: json
    })

    this.downloading.push({
      downloadProgress: true,
      id: id_val,
      name: fetchData.displayName,
      downloadStarted: false,
      fileDownload : false,
      status:'Waiting',
      csv: fetchData?.csv,
      message:''
    })
    this.downloadingArray.next(this.downloading)
  }

  Queing(){
    setInterval(()=>{
      for(let i=0; i< this.storageArray.length;i++){
        if(this.inProgress && !this.storageArray[i].downloadStarted && !this.storageArray[i].downloaded){
          this.inProgress = false
          this.ApiCall(i)
        }
        if(this.storageArray[i].downloadStarted && !this.storageArray[i].downloaded){
          return;
        }else if(this.storageArray[i].downloadStarted && this.storageArray[i].downloaded){
          this.inProgress = true;
        }
      }
    }, 2000)
  }





  ApiCall(index){
    //Download data
    let currentId = this.storageArray[index]?.id
    this.downloading[index].downloadStarted = true;
    this.downloading[index].status = 'Fetching';
    this.storageArray[index].downloadStarted = true;
    this.downloadingArray.next(this.downloading)
    let line = this.storageArray[index]
    // console.log(line,this.webApiHttp.ApiURLArray[line.url],this.storageArray[index],index)
    try {
      this.webApiHttp.Post(this.webApiHttp.ApiURLArray[line.url], line['jsonList']).then(res => {
        for(let i= 0; i< this.storageArray.length;i++){
          if(this.storageArray[i]?.id==currentId){
            index = i;
          }
        }
        if (res[0]?.condition?.toLowerCase() == 'true') {
          if(this.storageArray[index]?.csv){
            this.storageArray[index].data=res[0]?.message
            this.downloading[index].status='Ready'
          }
          // else{
          //   if (line.isMulti) {
          //     this.totalPages = res[0]?.total_rows;
          //     this.formatExcel(line?.cols, res, line.id)
          //     this.pageNo += 1;
          //     if (this.pageNo * this.rowCount < this.totalPages) {
          //       this.getData(this.fetchedData)
          //     }
          //   } else {
          //     this.formatExcel(line?.cols, res, line.id)
          //   }
          // }
        } else if (res[0]?.message?.toLowerCase() == 'no record found' || res[0]?.message?.toLowerCase() == 'no record found.') {
          if(!this.storageArray[index]?.csv) {
            this.formatExcel(line?.cols, res, line.id)
            this.totalPages = 0;
            this.pageNo = 0;
          }else{
            this.downloading[index].message = res[0]?.message?.length > 0?res[0]?.message: res?.message?.length>0?res?.message:'Something went wrong.' ;
            this.storageArray[index].message = res[0]?.message?.length > 0?res[0]?.message: res?.message?.length>0?res?.message:'Something went wrong.' ;
          }
        } else {
          // Enter Message Line if error occurs
          // console.log(res)
          this._toaster.warning(res[0]?.message?res[0]?.message:res?.message?res?.message:'Something went wrong.')
          this.downloading[index].message = res[0]?.message?.length > 0?res[0]?.message: res?.message?.length>0?res?.message:'Something went wrong.' ;
          this.storageArray[index].message = res[0]?.message?.length > 0?res[0]?.message: res?.message?.length>0?res?.message:'Something went wrong.' ;
          this.totalPages = 0;
          this.pageNo = 0;
        }
      }).catch(err => {
        console.log(err)
        this.downloading[index].message = err?.errors?.title;
        this.storageArray[index].message = err?.errors?.title;
      }).finally(() => {
        for(let i= 0; i< this.storageArray.length;i++){
          if(this.storageArray[i]?.id==currentId){
            index = i;
          }
        }
        if (this.storageArray[index]?.data.length < 1 && this.downloading[index].message == '') {
          this.storageArray[index].message = 'Something went wrong.'
          this.downloading[index].message = 'Something went wrong.'
        }
        try{
          this.storageArray[index]['downloaded'] = true;
          this.storageArray[index]['downloadProgress'] = false;
          this.downloading[index]['downloadProgress'] = false;
          this.downloadingArray.next(this.downloading)
        }catch (e) {
          console.log(e)
        }
      })
    }catch (err) {
      this.storageArray[index].message = 'Something went wrong.'
      this.downloading[index].message = 'Something went wrong.'
    }
  }





  formatExcel(columns: Array<any>, data: Array<any>, id: string,index?){
    let excel :Array<any> =[]

    // if(columns?.length<1){
    //   for(let i = 0; i< data?.length; i++){
    //     delete data[i]?.condition
    //   }
    //   excel = data;
    // }else {
    //   for (let i = 0; i < data?.length; i++) {
    //     let m = {}
    //     for (let x = 0; x < columns?.length; x++) {
    //       m[columns[x]] = data[i][columns[x]]
    //     }
    //     excel.push(m)
    //   }
    // }

    if(this.storageArray[index]?.csv){
      this.excel.exportAsExcelFile(excel, this.storageArray[index]?.name)
      // console.log(excel)
      this._toaster.success(this.storageArray[index]?.name + ' Downloaded Successfully')
    }else
    {
      this.downloading = this.downloading.map((ele, index) => {
        if (ele?.id == id) {
          this.storageArray[index].downloadProgress = false;
          this.storageArray[index].name = ele?.name;
          this.storageArray[index].data = excel;
          this.storageArray[index].downloaded = false
          return {
            id: ele?.id,
            name: ele?.name,
            downloadProgress: false,
            downloadStarted: false,
            message: '',
            fileDownload:false,
            fileSize:0,
            status:'Pending',
            gained:0
          }
        } else return ele
      })
      // console.log("this.download",this.downloading)
      this.downloadingArray.next(this.downloading)
    }
  }



  downloadFile(id, index?){

    if(this.storageArray[index].id == id){
      if(this.storageArray[index]?.csv){
        let e= this.storageArray[index].data
        // console.log("e <-->",e)
        this.getFileFromServer(e,index);
      }else{
        this.excel.exportAsExcelFile(this.storageArray[index]?.data, this.storageArray[index]?.name)
        this._toaster.success(this.storageArray[index]?.name +'Downloaded Successfully')
        // console.log("id-->",id,"INDex--->",index)
        this.deleteFile(id,index);
        // console.log("id-->",id,"INDex1--->",index)
      }
    }else{
      this._toaster.error('Something went wrong downloading the file.'+this.storageArray[index]?.name +' Try again.')
    }
  }


  async getFileFromServer(url,index){
    let resp =await fetch(this.webApiHttp.globalurl+'/'+url,{method: "GET"})
    this.downloading[index].status = 'Downloading';
    this.downloadingArray.next(this.downloading);
    (resp.text()).then((response) => {
      this.textToJSonFormating(response,index)
    }).catch(err => console.log(err))
  }



  CSVToJSON(csvData): any {
    let data = this.CSVToArray(csvData);
    let objData = [];
    console.log(csvData,data)
    for (let i = 1; i < data.length; i++) {
      objData[i - 1] = {};
      for (let k = 0; k < data[0].length && k < data[i].length; k++) {
        let key = data[0][k];
        objData[i - 1][key] = data[i][k]
      }
    }

    let jsonData = JSON.stringify(data);
    // console.log("JSON DATA---->",jsonData)
    jsonData = jsonData.replace(/},/g, "},\r\n");
    // console.log("stringify",jsonData)
    return JSON.parse(jsonData);
  }

  CSVToArray(csvData, delimiter?) {
    delimiter = (delimiter || ",");
    // @ts-ignore
    let pattern = new RegExp((
      "(\\" + delimiter + "|\\r?\\n|\\r|^)" +
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
      "([^\"\\" + delimiter + "\\r\\n]*))"), "gi");
    let data = [[]];
    let matches = null;
    while (matches = pattern.exec(csvData)) {
      let matchedDelimiter = matches[1];
      if (matchedDelimiter.length && (matchedDelimiter != delimiter)) {
        data.push([]);
      }
      if (matches[2]) {
        matchedDelimiter = matches[2].replace(
          // @ts-ignore
          new RegExp("\"\"", "g"), "\"");
      } else {
        matchedDelimiter = matches[3];
      }
      // data[data.length - 1].push(matchedDelimiter);
      data[data.length - 1].push(matchedDelimiter?matchedDelimiter:"");
    }
    // console.log(data,'csvtoArray before pop')
    data.pop()
    // console.log(data,'csvtoArray after pop')
    return (data);
  }




  private textToJSonFormating(response: string, index) {
    // console.log(response, 'text')
    let jsonData = this.CSVToJSON(response)
    let allKeys:Array<string>=this.storageArray[index]?.allCols;
    let selectedKeys:Array<string>= this.storageArray[index]?.cols;
    // console.log(allKeys);
    // console.log(selectedKeys);
    // console.log(jsonData);


    jsonData?.map(ele=>{
      delete ele['condition']
      for(let i=0; i< allKeys?.length;i++){
        if(selectedKeys.indexOf(allKeys[i])<0){
          delete ele[allKeys[i]]
        }
      }
    });
      // console.log("json length",jsonData?.length-1, jsonData[0])
      // console.log("json length---->",jsonData?.length-1)
      // console.log(jsonData?.length)


    this.excel.exportAsExcelFile(jsonData, this.storageArray[index]?.name);
    setTimeout(()=>{this._toaster.success(this.storageArray[index]?.name + ' Downloaded Successfully')},2000);
    this.downloading[index].fileDownload = false;
    this.downloading[index].gained = 0;
    this.downloading[index].fileSize = 0;
    this.downloading[index].status = 'Ready';
    this.downloadingArray.next(this.downloading);
  }

  deleteFile(id,index?:any){
    // if(index==-1) {
      for (let i = 0; i < this.downloading?.length; i++) {
        if (this.downloading[i]?.id == id) {
          index = i
          break
        }
      }
    // }
    if(this.storageArray[index]?.id == id){
      this.storageArray.splice(index,1)
      this.downloading.splice(index,1)
      // console.log("id-->",id,"<---->","Index1--->",index)
      this.downloadingArray.next(this.downloading)
    }
    this.Queing();
  }

  getURL(index){
    // console.log("Array--->",this.storageArray)
    // console.log("geturl---->",this.storageArray[index].data)
    return this.webApiHttp.globalurl+'/'+ this.storageArray[index].data;
  }


  copyText(index: any) {
    navigator.clipboard.writeText(this.webApiHttp.globalurl+'/'+ this.storageArray[index].data)
  }

}

export class storageArray {
  id: string;
  name: string;
  data: any;
  isMulti: boolean;
  downloadStarted: boolean;
  downloadProgress: boolean;
  downloaded: boolean;
  message: string;
  url: string;
  cols: any;
  jsonList: any;
  fileDownload?: boolean = false
  csv?: boolean;
  allCols: any
}

export class downloadArray{
  id: string;
  name: string;
  downloadProgress: boolean;
  downloadStarted: boolean;
  message:string;
  fileDownload?:boolean =false
  csv?:boolean;
  status:string = 'Waiting'
  fileSize?:any=0;
  gained?:any =0;
}

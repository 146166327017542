<div class="page-layout simple fullwidth inner-scroll" fxLayout="column" id="agroBucket">
  <div class="p-12" pristinePerfectScrollbar>
    <div class="mb-24" fxLayout="column" fxLayoutAlign="start">
      <div class="mat-card mat-elevation-z1" fxFlex="1 0 auto" fxLayout="column"
           fxLayoutAlign="start">
        <div class="h3 p-8" fxLayout="row" fxLayoutAlign="space-between center">
          <b>InDam Reports</b>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column" fxFlex class="p-16">
          <span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <mat-form-field fxFlex="35" appearance="outline">
              <mat-label>Options</mat-label>
              <mat-select [formControl]="menu">
                <mat-option [value]="ele" *ngFor="let ele of dropDown" (click)="assignValue(ele)">{{ele?.displayName}}</mat-option>
              </mat-select>
            </mat-form-field>
          </span>

          <mat-divider></mat-divider>
          <div  *ngIf="menu.value!=''" fxLayout="column">
            <!--            Filter Columns-->
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <h3 class="text-bold">{{display?.displayName}} Filters</h3>
              </div>
            <mat-divider></mat-divider>
            <mat-chip-list class="p-8" [selectable]="true" [multiple]="true" >
              <div class="mat-chip cursor-pointer"  [class]="e.selected?'dark':''" [fxHide]="e.type=='email'"
                   (click)="e.selected?'':e.selected = true;" fxLayout="row" *ngFor="let e of display?.filter_columns">
                <mat-checkbox class="mx-8 border-radius-16" color="warn" [checked]="e.selected" [(ngModel)]="e.selected"
                              [disabled]="!e.selected"></mat-checkbox>
<!--                <p class="p-0 m-0" *ngIf="e.type == 'text'">{{e.name}}<sup *ngIf="e?.required">*</sup>-->
<!--                  <input  [readOnly]="!e.selected" [(ngModel)]="e.filter_value" ></p>-->
<!--                <p class="p-0 m-0" *ngIf="e.type == 'date'">{{e.name}}<sup *ngIf="e?.required">*</sup>-->
<!--                  <input  [readOnly]="!e.selected" [(ngModel)]="e.filter_value" type="date"></p>-->
                <p class="p-0 m-0" *ngIf="e.type == 'text'">{{e.name}}<sup *ngIf="e?.required">*</sup> <input  [readOnly]="!e.selected" [(ngModel)]="e.filter_value" ></p>
                <p class="p-0 m-0" *ngIf="e.type == 'number'">{{e.name}}<sup *ngIf="e?.required">*</sup> <input  [readOnly]="!e.selected" type="number" [(ngModel)]="e.filter_value" ></p>
                <p class="p-0 m-0" *ngIf="e.type == 'range'">{{e.name}}<sup *ngIf="e?.required">*</sup> <input  [readOnly]="!e.selected" type="number" [(ngModel)]="e.filter_value" ></p>
                <p class="p-0 m-0" *ngIf="e.type == 'date'">{{e.name}}<sup *ngIf="e?.required">*</sup> <input  [readOnly]="!e.selected" [(ngModel)]="e.filter_value" type="date"></p>
                <p class="p-0 m-0" *ngIf="e.type == 'option'">{{e.name}}<sup *ngIf="e?.required">*</sup> <select  [disabled]="!e.selected" [(ngModel)]="e.filter_value">
                  <option class="w-100 p-8" *ngFor="let line of e.options" [value]="line">{{line}}</option>
                </select></p>
                <p class="p-0 m-0" *ngIf="e.type =='daterange'" >{{e.name}}<sup *ngIf="e?.required">*</sup>
                  <input  readOnly (click)="picker.open()"
                          [value]="((e.start | date:'dd/MM/yyyy')?(e.start | date:'dd/MM/yyyy'):'start date')
                          +' to '+((e.end | date:'dd/MM/yyyy')?(e.end | date:'dd/MM/yyyy'):'end date')" >

<!--                  [min]="minDate" [max]="maxDate"-->



<!--                    <mat-date-range-input [rangePicker]="picker">-->
<!--                      <input matStartDate placeholder="Start date">-->
<!--                      <input matEndDate placeholder="End date">-->
<!--                    </mat-date-range-input>-->
<!--                    <mat-date-range-picker #picker></mat-date-range-picker>-->


                  <mat-date-range-input style="visibility: hidden; height: 0; width: 0" [rangePicker]="picker" >
                    <input [(ngModel)]="e.start" matStartDate />
                    <input [(ngModel)]="e.end"  matEndDate />
                  </mat-date-range-input>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </p>
              </div>
            </mat-chip-list>

            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <span class="p-12" fxLayoutAlign="end center" fxLayoutGap="12px">
              <button  mat-raised-button color="warn" (click)="sending(display)" >Generate Report</button>
            </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>












<!--<mat-card class="m-20 p-8" style="width: 97%;max-height: 65vh !important;height: fit-content !important;">-->
<!--  <mat-card-header>-->
<!--    <div fxLayout="row" fxLayoutGap="auto"  style="width: 100%">-->
<!--      <div >-->
<!--        <b style="font-size: large;margin-top: 25px;">Indam Report</b>-->
<!--      </div>-->
<!--      <div>-->
<!--        <button  mat-icon-button matTooltip="Download"  style="margin-right: 45px" [matMenuTriggerFor]="menu"-->
<!--                 matBadgeColor="warn" matBadge="{{countBadge}}" *ngIf="hideDownlaod" ><mat-icon >cloud_download</mat-icon>-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </mat-card-header>-->
<!--  <mat-divider style="margin-top: 10px;"></mat-divider>-->


<!--  <mat-menu #menu>-->
<!--    <div  style="height: fit-content !important;max-height: 280px !important;" (click)="$event.stopPropagation();$event.preventDefault()">-->

<!--      <div style="height: 64px;border: 1px solid #ccd0de;border-top-left-radius: 30px;border-bottom-left-radius: 30px;margin-bottom: 4px"-->
<!--           mat-menu-item *ngFor="let co of CountArray;let i=index" (click) = "$event.stopPropagation()">-->
<!--        <span style="font-weight: bold">{{this.ReportName=='Liquidation Exception Report'?'liquidation Report':'Stock Report'}}</span>-->
<!--        <span style="float: right;width: 96px">-->
<!--          <a  href="{{combine}}"><mat-icon matTooltip="Download" class="download" (click) = "$event.stopPropagation()" >cloud_download</mat-icon></a>-->
<!--          <mat-icon class="link" matTooltip="Copy Link" [cdkCopyToClipboard]="copyText"  (click)="$event.stopPropagation();">link</mat-icon>-->
<!--          <mat-icon (click)="deleteReport(i);" matTooltip="Delete"  class="delete">delete</mat-icon>-->
<!--        </span>-->


<!--            <div fxLayout="row">-->
<!--              <div>-->
<!--                <p style="font-size: 0.8em;margin-top: -25px;margin-left: 23px">{{loading=='true'?'Fetching':'Ready'}}</p>-->
<!--              </div>-->
<!--              <div>-->
<!--                <span style="float: right;font-size: 0.8em;margin-top: -25px;margin-left: 100px" >{{updateTime | date:'h:m,a;dd-MMM'}}</span>-->
<!--              </div>-->
<!--            </div>-->

<!--        <mat-progress-bar mode="indeterminate" *ngIf="loading" style="margin-top: -27px"></mat-progress-bar>-->
<!--      </div>-->
<!--    </div>-->

<!--  </mat-menu>-->
<!--<br><br>-->

<!--  <div style="margin-left: 15px">-->
<!--    <mat-form-field appearance="outline" style="width: 290px;">-->
<!--      <mat-label>Option</mat-label>-->
<!--      <mat-select>-->
<!--        <mat-option *ngFor="let re of ReportsArray" (click)="rev(re)" [value]="re">{{re}}</mat-option>-->
<!--      </mat-select>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--  <mat-divider style=" margin-left: 20px;width: 96%"></mat-divider>-->
<!--  <p style="margin-left: 15px;padding: 12px">Liquidation Exception Report</p>-->
<!--  <mat-divider style="margin-top: -10px ;margin-left: 20px;width: 96%"></mat-divider>-->

<!--  <div style="margin-top: 30px;margin-bottom: 20px" fxLayout="row" fxLayoutGap="auto" *ngIf="hideDateDiv">-->

<!--    <div style="margin-left: 30px;padding-left: 20px;">-->
<!--      <div style="width: 430px;background-color: #e0e0e0;border-radius: 10px;padding: 4px;height: 37px" fxLayout="row">-->
<!--        <div>-->
<!--         <h3 style="width: 161px">Created Date Range &nbsp;</h3>-->
<!--        </div>-->
<!--        <div style="background-color: white;border-bottom-right-radius: 20px;border-top-right-radius: 20px;padding-top: 5px">-->
<!--          <mat-date-range-input [formGroup]="range" [rangePicker]="picker">-->
<!--            <input  style="padding-left: 1px" (keyup.backspace)="this.range.reset()"  readonly matInput matStartDate-->
<!--                    formControlName="start" placeholder="Start to End Date" (click)="picker.open()">-->
<!--            <input  style="padding-left: 4px" (keyup.backspace)="this.range.reset()"  readonly matInput matEndDate-->
<!--                    formControlName="end" (click)="picker.open()">-->
<!--          </mat-date-range-input>-->
<!--        </div>-->
<!--        <mat-date-range-picker #picker></mat-date-range-picker>-->
<!--      </div>-->

<!--    </div>-->
<!--    <div>-->
<!--      <button style="margin-right: 100px" mat-raised-button color="warn" (click)="check()"  [disabled]="range.invalid">Generate Report</button>-->
<!--    </div>-->

<!--  </div>-->




<!--</mat-card>-->


<!--&lt;!&ndash;<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">&ndash;&gt;-->
<!--&lt;!&ndash;            <mat-form-field fxFlex="35" appearance="outline">&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-label>Options</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;              <mat-select [formControl]="menu" >&ndash;&gt;-->
<!--&lt;!&ndash;                <mat-option [value]="ele" *ngFor="let ele of dropDown" (click)="assignValue(ele)">{{ele?.displayName}}</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;              </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;            </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;          </span>&ndash;&gt;-->

import { Component, OnInit } from '@angular/core';
import {WebApiHttp} from "../../../../@pristine/process/WebApiHttp.services";
import {Form, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {start} from "repl";
import {ToastrService} from "ngx-toastr";
import {MaxRangeSelectionStrategy} from "../../../../@pristine/process/DateRange.services";
import {MAT_DATE_RANGE_SELECTION_STRATEGY} from "@angular/material/datepicker";
import {SessionManageMent} from "../../../../@pristine/process/SessionManageMent";
import {ReportsStructure} from "./FilterArray";
import  * as dats from "./dropDownfilter.json"
import {ReportDownload} from "../../../../@pristine/process/report-download";

@Component({
  selector: 'app-exception-report',
  templateUrl: './exception-report.component.html',
  styleUrls: ['./exception-report.component.scss'],
  providers: [
    {provide: 'rangeMax', useValue: 30},
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy
    }
  ]
})
export class ExceptionReportComponent implements OnInit {

  date = new Date();
  loading: boolean = false;
  menu: FormControl = new FormControl('');

  dropDown: Array<ReportsStructure> = dats?.fil
  display: ReportsStructure = null

  minDate: Date
  maxDate: Date

  constructor(private WebApiHttp: WebApiHttp,
              private datePipe: DatePipe,
              private _formBuilder: FormBuilder,
              private toasterService: ToastrService,
              private session: SessionManageMent,
              private _download: ReportDownload,) {

    // const currentYear = new Date().getFullYear();
    // this.minDate = new Date(currentYear - 20, 12, 99);
    // console.log(this.minDate.toDateString());
    // this.maxDate = new Date(currentYear + 1, 11, 31);

  }



  ngOnInit(): void {
    this.valToJson()
  }

  sending(disp) {
    // console.log("disp",disp)
    if(disp?.name == '')
    {
      if(!disp?.filter_columns[1].selected && !disp?.filter_columns[2].selected && !disp?.filter_columns[3].selected&& !disp?.filter_columns[4].selected)
      {
        this.toasterService.error('Select atleast One date range');
        this.assignValue(this.menu.value);
        // console.log(this.menu.value);
        return
      }
    }
    this.assignValue(this.menu.value)
    this._download.getData(disp)
  }

  assignValue(value: any) {
    // console.log("assign-value",value)
    this.display=JSON.parse(JSON.stringify(value))
  }

  async valToJson() {
  }


}
